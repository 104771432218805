import React, { useState } from "react";

import './video-uploader.css'
import { videoTemplateState } from "../../store/slice/VideoSlice";

interface ParentState {
    video: File|any;
    error:string|any;
}
  
interface Props {
    state: videoTemplateState | undefined;
    setState: (video:File|undefined, error:string|undefined) => void;
}

const VideoUploader: React.FC<Props> = ({ setState, state }) => {

  let maxFileSize = 1000000000;

  function handleDrop(event: React.DragEvent<HTMLDivElement>) {

    event.preventDefault();

    const file = event.dataTransfer.files[0];
    if (file.type.startsWith("video/")) {
      if (maxFileSize && file.size > maxFileSize) {
        setState(undefined,'Exceeded file limit');
      } else {
        setState(file,undefined);
      }
    } else {
      setState(undefined, "Please drop a video file.");
    }
  }

  function handleSelect(event: React.MouseEvent<HTMLButtonElement>) {

    const input = document.createElement("input");
    input.type = "file";
    input.accept = "video/*";
    input.onchange = (event) => {
        const file = (event.target as HTMLInputElement)?.files?.[0];
        if (file) {
            if (maxFileSize && file.size > maxFileSize) {
              setState(undefined,'Exceeded file limit');
            } else {
              setState(file,undefined);
            }
        }
    };
    input.click();
  }

  return (
    <div
      onDragOver={(event) => event.preventDefault()}
      onDrop={handleDrop}
      className="video-uploader-element"
    >
     
        
        {
            state && state.error && (
                <span style={{ color: "red" }}>{state.error}</span>
            )
        }
    
           
        <p className="video-select-label">
            Drop a video file here
        </p>
        <p className="video-select-label-or">OR</p>
        <button className="video-select-btn" onClick={handleSelect}>Select Video</button>
          
      
    </div>
  );
};

export default VideoUploader;
