import React from 'react'
import pages from '../../utils/pagesInfo'
import { GrFormNext } from 'react-icons/gr'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/reducer'

import './editor-nav.css'

type Props = {}

export default function EditorNav({}: Props) {

    let currentPage = useSelector((state:RootState) => state.template).currentPage

  return (
    <nav className='editor-nav-parent'>
        <ul className='editor-nav-list'>
        {
                pages.map((item,index) => {
                if(index === pages.length -1) return 
                return (
                <React.Fragment key={index}>
                    {
                        index - 1 > -1 &&
                        <li key={index + 55} className='nav-list-item-arrow'> <GrFormNext /></li>
                    }
                    <li key={index} className={index === currentPage ? 'nav-list-item active' : 'nav-list-item'}>{item.title}</li>
                    
                </React.Fragment>)})
            }
        </ul>
    </nav>
  )
}