import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import pages from '../../../utils/pagesInfo';

import {BsArrowLeft} from 'react-icons/bs'
import { Autocomplete, CircularProgress, MenuItem, TextField } from '@mui/material';

import './player-info.css'

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducer';
import { updateAutoComplete, updatePlayerState } from '../../../store/slice/PlayerSlice';
import { updateProcessingStatus } from '../../../store/slice/ProgressSice';

import axios from 'axios'
import { GrFormNext, GrFormNextLink } from 'react-icons/gr';
import { playerPositions } from '../../../utils/constants';
import CustomButton from '../../../components/CustomButton/CustomButton';

const PlayerInfoPage: React.FC = () => {
  
  let navigate = useNavigate()
  const dispatch = useDispatch();

  const player = useSelector((state:RootState) => state.player);
  let userId =  useSelector((state:RootState) => state.user).userId
  let templateState = useSelector((state:RootState) => state.template)

  let base_url = process.env.REACT_APP_SERVER_URL

  let isFirstTime = useRef(true)
  useEffect(() => {
    if(!isFirstTime.current) return
    isFirstTime.current = false
    
    if(templateState.value === undefined || templateState.value === -1)
        navigate('/tool')
    
  },[])


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>|React.ChangeEvent<HTMLTextAreaElement>|any, type:string) => {
    dispatch(updatePlayerState({ key : type, value: event.target.value }));
  };
  const handlePositionChange = (event: React.ChangeEvent<{}>, value: string[]) => {
    dispatch(updateAutoComplete(value));
  };

  let [isLoading, setIsLoading] = useState(false)
  let handleNextClick = async() => {

    if(isLoading) return
    if(player === undefined || player['fullname'] === undefined 
    || player['fullname'] === '' || player['grad_year'] === undefined  || player['grad_year'] === ''||
    player['school'] === undefined || player['school'] === '' || player['gpa'] === undefined || player['gpa'] === ''
    || player['phone'] === undefined || player['phone'] === '' || player['email'] === undefined || player['email'] === ''
    || player['twitter'] === undefined || player['twitter'] === '' || player['selectedPositions'] === undefined || player['selectedPositions'].length === 0 
    ){
      alert('Please fill the required fields!')
    } else {

      setIsLoading(true)
  
      let playerData = {...player, userId}

      const headers = {
        'Content-Type': 'application/json',
      }
      let apiResp = await axios.post(`${base_url}/uploadPlayer`,playerData,{headers:headers})
      console.log(apiResp)
      setIsLoading(false)
      if(apiResp.status === 200)
        navigate('/tool/videos')
    }
  }

  return (
    <div className='player-page'>
     
      <div className='player-form-parent'>

        <div className='editor-page-header inp_full'>

          <h1 className='page-heading'>{pages[1].pageHeading}</h1>
          <button className='editor-prev-btn' onClick={() => navigate('/tool/')}><BsArrowLeft /> Previous step</button>
        </div>
        
        <TextField id="inp_player_name" label="Full name" variant="outlined" className='inp_75' value={player.fullname} onChange={(e) => handleInputChange(e, 'fullname')}/>
        <TextField id="inp_grad_year" label="Graduation year" variant="outlined" className='inp_25'
          value={player.grad_year}
          onChange={(e) => handleInputChange(e, 'grad_year')}/>

        <TextField id="inp_hs" label="High school" variant="outlined" className='inp_75' 
             value={player.school}
        onChange={(e) => handleInputChange(e, 'school')}/>
        <TextField id="inp_gpa" label="GPA" variant="outlined" className='inp_25' inputMode='decimal' 
             value={player.gpa}
        onChange={(e) => handleInputChange(e, 'gpa')}/>

        <Autocomplete
        multiple
        id="tags-outlined"
        className='inp_full'
        value={player.selectedPositions}
        options={playerPositions}
        onChange={(e,v) => handlePositionChange(e,v)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Positions"
            placeholder="Select multiple positions"
          />
        )}
      />


        <TextField id="inp_phone" label="Mobile number" variant="outlined" className='inp_30' value={player.phone} onChange={(e) => handleInputChange(e, 'phone')}/>
        <TextField id="inp_email" label="Email" variant="outlined" className='inp_60' value={player.email} onChange={(e) => handleInputChange(e, 'email')}/>
        <TextField id="inp_twitter" label="Twitter" variant="outlined" className='inp_90' value={player.twitter} onChange={(e) => handleInputChange(e, 'twitter')}/>

        <div className="action inp_full" style={{width:'100%', display:'flex',alignItems:'flex-end',justifyContent:'flex-end'}}>
          <CustomButton content='Next' isLoading={isLoading} icon={<GrFormNextLink />}  iconPlacement='right' clickHandler={handleNextClick}/>      
        </div>
        
      </div>

    
      
    </div>
  );
};

export default PlayerInfoPage;