import React from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'

import './toolheader.css'

type Props = {
    title:string,
    path?:string
}

export default function ToolHeader({title,path}: Props) {


    let navigate = useNavigate()
    return (
        <div className='editor-page-header'>
            <h1 className='page-heading'>{title}</h1>
            {
                path &&
                <button className='editor-prev-btn' onClick={() => navigate(path)}><BsArrowLeft /> Previous step</button>
            }
           
        </div>
    )
}