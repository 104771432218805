
export let instruction = [
    `Set up your camera at a distance and angle that allows you to capture the entire body of the player while they are throwing or hitting. Ideally, the camera should be positioned at the side of the player and slightly above or at eye level.`,
    `Make sure that the camera is stabilized to avoid shaky footage. You can use a tripod or another stable surface to hold the camera in place.`,
    `Record the player throwing or hitting from multiple angles, including a straight-on view and a back view, in addition to the side view. This will give recruiters a better idea of the player's mechanics and form.`,
    `Ensure that the lighting is good enough to see the player clearly. Avoid shooting in direct sunlight or in low light conditions, which can create harsh shadows or grainy footage.`,
    `Record the video in high definition if possible, as this will provide better clarity and detail for recruiter.`,
    `Make sure to include the player's name, position, and contact information at the beginning or end of the video.`,
    `edit the video if necessary to remove any unnecessary footage or to add annotations or graphics to highlight key points.`
]

export const baseball_pitcher_video_template = [
    {
        id : 'b_h_vid_1',
        title : 'Hitting (Side view)',
        sampleUrl:'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        instruction,
        index : 0,
        rec_vid_len:10
    },
    {
        id : 'b_h_vid_2',
        title : 'Hitting (Rear view)',
        sampleUrl:'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
        instruction,
        index : 1,
        rec_vid_len:30
    },
    {
        id : 'b_h_vid_3',
        title : 'Running 60Yd',
        sampleUrl:'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        instruction,
        index : 2,
        rec_vid_len:20
    },
    {
        id : 'b_h_vid_4',
        title : 'Fielding',
        sampleUrl:'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        instruction,
        index : 3,
        rec_vid_len:20
    }
]

export const baseball_position_video_template = [
    {
        id : 'b_h_vid_1',
        title : 'Hitting (Side view)',
        sampleUrl:'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        instruction,
        index : 0,
        rec_vid_len:30
    },
    {
        id : 'b_h_vid_2',
        title : 'Hitting (Rear view)',
        sampleUrl:'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        instruction,
        index : 1,
        rec_vid_len:30
    },
    {
        id : 'b_h_vid_3',
        title : 'Running 60Yd',
        sampleUrl:'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        instruction,
        index : 2,
        rec_vid_len:20
    },
    {
        id : 'b_h_vid_4',
        title : 'Fielding',
        sampleUrl:'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        instruction,
        index : 3,
        rec_vid_len:10
    }
]

export const softball_pitcher_video_template = [
    {
        id : 'b_h_vid_1',
        title : 'Hitting (Side view)',
        sampleUrl:'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        instruction,
        index : 0,
        rec_vid_len:20
    },
    {
        id : 'b_h_vid_2',
        title : 'Hitting (Rear view)',
        sampleUrl:'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',

        instruction,
        index : 1,
        rec_vid_len:20
    },
    {
        id : 'b_h_vid_3',
        title : 'Running 60Yd',
        sampleUrl:'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',

        instruction,
        index : 2,
        rec_vid_len:30
    },
    {
        id : 'b_h_vid_4',
        title : 'Fielding',
        sampleUrl:'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        instruction,
        index : 3,
        rec_vid_len:40
    }
]

export const softball_position_video_template = [
    {
        id : 'b_h_vid_1',
        title : 'Hitting (Side view)',
        sampleUrl:'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',

        instruction,
        index : 0,
        rec_vid_len:20
    },
    {
        id : 'b_h_vid_2',
        title : 'Hitting (Rear view)',
        sampleUrl:'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        instruction,
        index : 1,
        rec_vid_len:10
    },
    {
        id : 'b_h_vid_3',
        title : 'Running 60Yd',
        index : 2,
        sampleUrl:'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',

        instruction,
        rec_vid_len:30
    },
    {
        id : 'b_h_vid_4',
        title : 'Fielding',
        sampleUrl:'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',

        instruction,
        index : 3,
        rec_vid_len:30
    }
]