import React, { useCallback, useEffect, useState } from 'react';
import './video-container.css'

import VideoUploader from '../../components/VideoUploader';

import ReactPlayer from 'react-player'
import VideoPlayer from '../../components/VideoPlayer';

import {AiOutlineDelete} from 'react-icons/ai'
import {ImFolderUpload} from 'react-icons/im'
import InstructionDialog from '../../components/InstructionDialog/InstructionDialog';
import { useDispatch } from 'react-redux';
import { addVideo, deleteVideo, setVideoError } from '../../store/slice/VideoSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducer';

import LinearWithValueLabel from '../../components/ProgressBar';

import { resetVideoProgress } from '../../store/slice/ProgressSice';
import VideoActionItem from '../../components/VideoActionItem';

interface Props {
  
}

const VideoSelectContainer: React.FC<Props> = ({}) => {


    const selectedIndex = useSelector((state:RootState) => state.video).videoSelectpage

    const videoElement = useSelector((state:RootState) => state.video).videoTemplate[selectedIndex]
   
    const [isInstructionOpen, setIsOpen] = useState(false)

    const video = useSelector((state:RootState) => state.video).videoTemplate[selectedIndex]

    const dispatch = useDispatch();

    const setSelecttedVideo = (input:File|undefined, error:string|undefined) => {
        if(error !== undefined){
            dispatch(setVideoError({index:selectedIndex,video:undefined, error}))
            return
        }
        if(input === undefined)
            dispatch(deleteVideo({index:selectedIndex}))
        else
            dispatch(addVideo({index:selectedIndex,video:input}))
    }

    const handleDeleteVideo = () => {
        dispatch(deleteVideo({index:selectedIndex}))
        dispatch(resetVideoProgress({index:selectedIndex}))
    }


    function sizeInMb(bytes:number) {
        var i = Math.floor(Math.log(bytes) / Math.log(1024));
        let mb = (bytes / Math.pow(1024, 2)).toFixed(2);
        return mb;
    }

    return (
        <div className='video-select-container'>

            <InstructionDialog isOpen={isInstructionOpen} toggleModal={setIsOpen} instruction={videoElement?.instruction}
            title={videoElement?.title}
            />
            <div className='video-left-panel'>

                {
                video && video.video !== undefined ? (
                    <>
                        <VideoPlayer url={video.video !== undefined && URL.createObjectURL(video.video)} />
                        <div className='select-video-footer'>
                        <p>{sizeInMb(video.video.size)} MB</p> 
                            {
                                video.rec_vid_len !== undefined &&
                                <p>
                                    Recommended video length : {video.rec_vid_len} sec
                                </p>
                            }
                          
                             
                            
                            <button onClick={handleDeleteVideo}><AiOutlineDelete/> Delete</button>
                        </div>

                        <VideoActionItem index={selectedIndex}/>

                        
                    </>
                    
                
                    ) :
                    <>
                    
                        <VideoUploader setState={setSelecttedVideo} state={video}/>
                        <div className='instruction-btn' onClick={() => setIsOpen(!isInstructionOpen)}>
                            Click here for instructions and examples
                        </div>
                    </>
                   
                }

                
            </div>
            <div className='video-right-panel'>
                <div className='sample-video-container'>
                    <p>Sample video - {videoElement?.title}</p>
                    <VideoPlayer url={videoElement?.sampleUrl}/>
                </div>
                
            </div>
        </div>
    );
};

export default VideoSelectContainer;
