import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { stat } from 'fs';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { instruction } from '../../utils/videolist';


export interface videoTemplateState {
    id:string,
    title:string,
    sampleUrl?:string|undefined,
    instruction?:string[]|undefined,
    isSelected?:boolean,
    index:number;
    rec_vid_len?:number;
    video?:File|undefined,
    error?:string|undefined,
}

export interface videosState {
    videoSelectpage:number,
    videoTemplate: videoTemplateState[]
}

export const initialState:videosState = {
    videoSelectpage:0,
    videoTemplate:[]
}

const videosSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    addVideo: (state:videosState, action: PayloadAction<{ index: number; video: File|undefined}>) => {
        const { index, video } = action.payload;
        let findEl = state.videoTemplate[index]
        if(findEl !== undefined){
            findEl.video = video
            findEl.isSelected = true
        }
      },

      setVideoError: (state:videosState, action: PayloadAction<{ index: number; video: undefined, error:string}>) => {
        const { index, video, error } = action.payload;
        let findEl = state.videoTemplate[index]
        if(findEl !== undefined){
            findEl.video = video
            findEl.isSelected = true
            findEl.error = error
        }
      },

      deleteVideo: (state:videosState, action: PayloadAction<{ index: number; }>) => {
        const { index } = action.payload;
        let findEl = state.videoTemplate[index]
        if(findEl !== undefined){
          findEl.video = undefined
        } 
      },
      
      videoSelectNextPage:(state:videosState) => {
        if(state.videoTemplate?.length > state.videoSelectpage)
            state.videoSelectpage += 1
      },

      videoSelectPrevPage:(state:videosState) => {
        if(state.videoSelectpage > 0)
          state.videoSelectpage -= 1
      },

      videoSelectSetPage:(state:videosState, action: PayloadAction<{ index: number|undefined; }>) => {
        let { index } = action.payload;
        if(index === undefined) index = 0

        if(index > state.videoTemplate.length) return

        state.videoSelectpage = index
      },


      setVideoTemplate:(state:videosState, action: PayloadAction<{ template: videoTemplateState[]; }>) => {
        const {template} = action.payload
        state.videoTemplate = template
      },

      addToTemplate:(state:videosState) => {
        
        let lastItem = state.videoTemplate.at(state.videoTemplate.length -1)

        if(lastItem?.index === undefined) return

        let newIndex = lastItem?.index + 1

        let item:videoTemplateState = {
          id: 'b-' + newIndex + '-vid',
          title:'Video ' + newIndex,
          sampleUrl:undefined,
          instruction: instruction,
          video:undefined,
          isSelected:false,
          index:newIndex
        }

        state.videoTemplate?.push(item)
      },

      removeFromTemplate:(state:videosState, action: PayloadAction<{ index: number; }>) => {
        
        let {index} = action.payload;
        if(state.videoTemplate !== undefined){
          state.videoTemplate.splice(index,1)
        }
      }

  }
});

export const {addVideo,setVideoError, deleteVideo,
  videoSelectNextPage,videoSelectPrevPage, videoSelectSetPage, setVideoTemplate,addToTemplate,removeFromTemplate
} = videosSlice.actions

export const videosReducer = videosSlice.reducer