import React, { useState } from 'react';
import './video-player.css'

interface Props {
  url: string | any;
}

const VideoPlayer: React.FC<Props> = ({ url }) => {
 
  return (
    <div className='video-player-wrapper'>
        <video className='video-player' src={url} controls controlsList="nodownload"/>
    </div>
  );
};

export default VideoPlayer;