import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import './processing.css'
import VideoPlayer from '../../../components/VideoPlayer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducer';
import LinearWithValueLabel from '../../../components/ProgressBar';

import LogoImg from '../../../assets/final-logo-img.png'

const ProcessingPage: React.FC = () => {
  
  let navigate = useNavigate()

  let processingState = useSelector((state:RootState) => state.progress).processingProgress
  let outputState = useSelector((state:RootState) => state.output).previewUrl

  // let videoUrl =  'https://timer.we-do-clever.com/streamVideo/'+localStorage.getItem('userId')
  

  const handleDownload = () => {

    if(!outputState ) return

    const anchor = document.createElement('a');
    anchor.href = outputState;
    anchor.download = 'output-file.mp4';
    anchor.click();
  };

  let templateState = useSelector((state:RootState) => state.template)
  let isFirstTime = useRef(true)
  useEffect(() => {
    if(!isFirstTime.current) return
    isFirstTime.current = false
    
    if(templateState.value === undefined || templateState.value === -1)
        navigate('/tool')
    
  },[])

  return (


    <div className='processing-page'>
      
      {
        processingState?.progressStatus === 'COMPLETED'?
      
        <>
           <div className='processing-container'>
        
            <h1>
              Hurray 🎉 <br />
              Your video is ready.
            </h1>

            <div className='process-video-container'>
              <VideoPlayer url={outputState}/>
            </div>

            <button className='dowload-btn' onClick={() => handleDownload()}>Download Now &nbsp;&nbsp;&nbsp; $0</button>
          </div>
      

          <img src={LogoImg} className='process-hero-img' alt="" />
        </> :

        <>
          <div className='processing-progress-container'>
                <div style={{display:'flex', flexDirection:'column', gap:'10px', width:'100%'}}>
                    <LinearWithValueLabel progress={processingState?.progress ? processingState.progress : 0}/>
                    <p>Your video is being processed. Please wait...</p>
                </div>

          </div>
        </>

      }
     
    </div>
  );
};

export default ProcessingPage;