import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';


export interface userState {
    userId:string,
    loginTs:string
}

export const initialState:userState = {
    userId:'',
    loginTs:''
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserId: (state:userState, action: PayloadAction<{ userId: string}>) => {
        const { userId } = action.payload;
        state.userId = userId
      },

    updateUserLoginTs: (state:userState, action: PayloadAction<{ ts: string}>) => {
    const { ts } = action.payload;
    state.loginTs = ts
    },
  }
});

export const {updateUserId, updateUserLoginTs} = userSlice.actions

export const userReducer = userSlice.reducer