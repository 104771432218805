import React, { useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import { Outlet } from "react-router-dom";

import { useParams } from 'react-router-dom';

import pages from '../../utils/pagesInfo';

import './editor-page.css'
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducer';

import { getS3Config } from '../../utils/common-helper';

// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

import AWS from 'aws-sdk';
import { useDispatch } from 'react-redux';
import { resetVideoUpload, setUploadActive, setUploadInActive, updateDownloadStatus, updateProcessingStatus, updateVideoProgressStatus } from '../../store/slice/ProgressSice';

import axios from 'axios'

import { updatePreviewUrl } from '../../store/slice/OutputSlice';

import { useLocation } from "react-router-dom";
import { setCurrentPage } from '../../store/slice/TemplateSlice';

import { GrFormNext } from 'react-icons/gr';

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { updateUserId, updateUserLoginTs } from '../../store/slice/UserSlice';
import { json } from 'stream/consumers';
import { PutObjectRequest } from 'aws-sdk/clients/s3';
import EditorNav from '../../components/EditorNav/EditorNav';

const EditorPage: React.FC = () => {

    const navigate = useNavigate();
    const { slug } = useParams()

    let dispatch = useDispatch()

    let processingState = useSelector((state:RootState) => state.progress).processingProgress

    let videoUploadState = useSelector((state:RootState) => state.progress).videoUpload

    let videoState = useSelector((state:RootState) => state.video).videoTemplate

    let currentPage = useSelector((state:RootState) => state.template).currentPage

    let userId =  useSelector((state:RootState) => state.user).userId
    
    let location = useLocation()

    let s3 = getS3Config().s3
    let BUCKET_NAME:string = getS3Config().BUCKET_NAME

    let base_url = process.env.REACT_APP_SERVER_URL
    let socket_url = process.env.REACT_APP_SOCKET_URL

    let underMaintaince = process.env.REACT_APP_UNDER_MAINTAINANCE === 'true' ? true : false

    useEffect(() => {
        let path = location.pathname

        if(underMaintaince){
            navigate('/maintenance')
            return
        }

        console.log('Path ' + path)
        switch(path){
            case '/tool/':
            case '/tool':dispatch(setCurrentPage(0)); break;
            case '/tool/player':
            case '/tool/player/':dispatch(setCurrentPage(1)); break;
            case '/tool/videos' : 
            case '/tool/videos/' : dispatch(setCurrentPage(2)); break;
            case '/tool/processing':
            case '/tool/payment':
            case '/tool/processing' : dispatch(setCurrentPage(3)); break;
        }
    }, [location]);


    let isFirstTime = useRef(true)
    useEffect(() => {

        if(!isFirstTime.current) return
        isFirstTime.current = false

        const userId = uuidv4()
        
        dispatch(updateUserId({userId}))
        let loginTs= +new Date() + ''
        dispatch(updateUserLoginTs({ts:loginTs}))

        // function handleContextMenu(e:any) {
        //     e.preventDefault(); // prevents the default right-click menu from appearing
        //   }
        //   // add the event listener to the component's root element
        //   const rootElement = document.getElementById('root');
        //   rootElement?.addEventListener('contextmenu', handleContextMenu);
        //   // remove the event listener when the component is unmounted
      
        //   return () => {
        //     rootElement?.removeEventListener('contextmenu', handleContextMenu);
        //   };
    },[])

    useEffect(() => {

        if(processingState === undefined) return

        if(processingState.progressStatus === 'PROCESSING_SUBMITTED'){

            startProcessing();
            
        }
    },[processingState])



    useEffect(() => {

        console.log('Use effect invoked ',videoUploadState);
        if(videoUploadState === undefined) return

        if(videoUploadState.status === 'START_UPLOADING'){

            dispatch(resetVideoUpload())

            let videoItem = videoState.at(videoUploadState.index)
            let videoId = videoItem?.index + '-vid'
            let video = videoItem?.video

            if(!videoId || !video || !videoItem) {
                alert('Input error')
                return
            }

        
            startUploading(userId,videoId,video, videoUploadState.index);

        }
    },[videoUploadState])


    const startUploading = async(userId:string|null,videoId:string|undefined,video:File|undefined ,index:number) => {

        console.log('Start Upload called');
            
         if(userId && videoId && video){

            dispatch(updateVideoProgressStatus({index:index,status:'UPLOADING',progress:20}))
            dispatch(setUploadActive())

            let videoExt = video?.name.split('.')[1]
            let videoName = videoId+'.'+videoExt
            let key = userId + '/inputRaw/' + videoName

            if(await uploadVideoToS3(index,key,video)){
                let apiResp = await axios.post(`${base_url}/uploadOptimize`,{userId,videoKey:videoName})
                if(apiResp.status === 200){
                    if(apiResp.data['taskId']){
                        startVideoSocket(apiResp.data['taskId'],index)
                    }
                }
            }
           
        }   

    }


    const uploadVideoToS3 = async(index:number,videoKey:string,video:File) => {

        try {
            const params:PutObjectRequest = {
                Bucket: BUCKET_NAME,
                Key: videoKey,
                Body: video,
                ACL: 'private',
              };
            
            const managedUpload = s3.upload(params);

            managedUpload.on('httpUploadProgress', progress => {
            const uploadedBytes = progress.loaded;
            const totalBytes = progress.total;
            const percentage = Math.round((uploadedBytes / totalBytes) * 100);
                console.log(`Uploading: ${percentage}%`);
                dispatch(updateVideoProgressStatus({index:index,progress:percentage}))
            });

            let resp = await managedUpload.promise();
            return true

        } catch(e:any){
            console.log(e)
            return false
        }
        
    }
    
    let startVideoSocket = (taskId:string,index:number) => {

        dispatch(updateVideoProgressStatus({index:index,status : 'OPTIMIZING'}))

        let WS_URL = `${socket_url}?userId=${userId}` 
        const newSocket = new WebSocket(WS_URL);

        // Send the userId as an initial payload when the connection is established
        newSocket.onopen = () => {
            console.log('Opened')
            if(userId !== null)
                newSocket.send(userId);
        };

        newSocket.onmessage = (event) => {
            const data = JSON.parse(event.data);

            if(data['taskId'] !== null && data['taskId'] !== undefined &&
             data['taskId'] === taskId){
                let status = data['status']
                let progress = data['progress']

                if(status === 'TASK_COMPLETED' || progress === 100){
                    
                    dispatch(updateVideoProgressStatus({index:index,status : 'COMPLETED',progress:100}))
                    dispatch(setUploadInActive())

                    newSocket.close()
                    return
                }

                dispatch(updateVideoProgressStatus({index:index,status : 'OPTIMIZING',progress}))


             }
          };

    }

    let processingLock = useRef(false)
    const startProcessing = async() => {
        
        if(processingLock.current) {
            alert('Task in progress, please wait.')
            return
        }
        processingLock.current = true

        try {
            dispatch(updateProcessingStatus({status:'PROCESSING',progress:0}))

            let processResp = await axios.post(`${base_url}/startProcessing`,{userId})
            if(processResp.status === 200 && processResp.data)
            {
                dispatch(updateProcessingStatus({status:'PROCESSING',progress:5}))
                startProcessingSocket(processResp.data['taskId'])
                navigate('processing')
                
            } else {
                alert('Unknown error')
                processingLock.current = false
            }
        } catch(e){
            alert(e)
            processingLock.current = false
        }
       

    }


    let startProcessingSocket = (taskId:string) => {

        let WS_URL = `${socket_url}?userId=${userId}` 
        const newSocket = new WebSocket(WS_URL);

        // Send the userId as an initial payload when the connection is established
        newSocket.onopen = () => {
            if(userId !== null)
                newSocket.send(userId);

        };

        newSocket.onclose = () => {
            console.log('Sokcet is closed!!')
            
        }

        newSocket.onmessage = (event) => {
            const data = JSON.parse(event.data);

            if(data['taskId'] !== null && data['taskId'] !== undefined &&
             data['taskId'] === taskId){
                let status = data['status']
                let progress = data['progress']

                if(status === 'TASK_COMPLETED' || progress === 100){
                    processingLock.current = false
                    dispatch(updateProcessingStatus({status:'PROCESSING',progress:98}))
                    getPreviewUrl()
                    newSocket.close()
                    return
                }

                dispatch(updateProcessingStatus({status:'PROCESSING',progress:progress}))

                
             }
          };

    }


   const getPreviewUrl = async() => {

        let resp = await axios.get(`${base_url}/streamVideo/${userId}`)

        if(resp.status === 200 && resp.data){
            let url = resp.data['videoUrl']
            dispatch(updatePreviewUrl({url}))
            dispatch(updateProcessingStatus({status : 'COMPLETED',progress:100}))
            dispatch(updateDownloadStatus({status:'COMPLETED',progress:100}))
        }
       
   }


   const initialOptions = {
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID ? process.env.REACT_APP_PAYPAL_CLIENT_ID : '',
        currency: "USD",
        intent: "capture",
    };

    let runS3Validation = async() => {

        let resp:any =  s3.getBucketAccelerateConfiguration()
        console.log('getBucketAccelerateConfiguration',resp);

        let resp2 = s3.config
        console.log('config',resp2);
         let buckets = await s3.listBuckets().promise()
         console.log(buckets);
         
        
    }
    

    return (
        <div className='editor-container'>
            {/* <button onClick={() => startProcessing()}>Start processing</button> */}
            {/* <button onClick={() => getPreviewUrl()}>Get preview</button> */}
            <EditorNav />
         
            {/* <PayPalScriptProvider options={initialOptions}> */}
                <Outlet />
            {/* </PayPalScriptProvider> */}
        </div>
    );
};

export default EditorPage;