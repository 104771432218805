import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { videoTemplateState } from './VideoSlice';
import { stat } from 'fs';


interface ProgressState {
  videoProgress:{
    progressStatus?:string;
    progress:number;
  }[],
  videoUpload?:{
    index:number;
    status:string
  },
  uploadActive?:boolean;
  processingProgress:{
    progressStatus:string;
    progress:number;
  } | undefined;
  downladProgress?:{
    progressStatus:string;
    progress:number;
  }
}

const progressSlice = createSlice({
  name: 'progress',
  initialState: { 
    videoProgress:[],
    processingProgress:undefined,
    uploadActive:false
   } as ProgressState,
  reducers: {
    updateVideoProgressStatus : (state, action: PayloadAction<{index:number,status?:string,progress?:number}>) => {
        const {index,status,progress} = action.payload;

        if(index > state.videoProgress?.length) return
        let findEl = state.videoProgress[index]


        if(findEl !== undefined)
        {
            if(status)
              state.videoProgress[index].progressStatus = status

            if(progress)
              state.videoProgress[index].progress = progress
            return
        }

      },

      resetVideoProgress : (state, action: PayloadAction<{index:number}>) => {
          const {index} = action.payload;
      
          if(index > state.videoProgress?.length) return

          let findEl = state.videoProgress[index]
          if(findEl !== undefined)
          {
              state.videoProgress[index].progress = 0;
              state.videoProgress[index].progressStatus = ''
              return
          }
      },

      initiateProgressTemplate: (state, action: PayloadAction<{templates:videoTemplateState[]}>) => {
        state.videoProgress = []
        const {templates} = action.payload
        templates.forEach(e => state.videoProgress.push({progress:0,progressStatus:''}))
      },

      addVideoProgress: (state) => {
      
        if(state.videoProgress === undefined) state.videoProgress = []
          
        state.videoProgress.push({progress:0,progressStatus:''})
        
      },
      
      removeVideoProgress: (state, action: PayloadAction<{index:number}>) => {
        const {index} = action.payload
        if(state.videoProgress !== undefined && state.videoProgress.length > index){
          state.videoProgress.splice(index,1)
        }
      },


      updateProcessingStatus : (state, action: PayloadAction<{status?:string, progress?:number}>) => {
        const {status,progress} = action.payload;

        if(state.processingProgress === undefined) state.processingProgress = {progressStatus:'',progress:0}

        if(status)
          state.processingProgress.progressStatus = status
        if(progress)
          state.processingProgress.progress = progress
      },


      updateDownloadStatus : (state, action: PayloadAction<{status?:string, progress?:number}>) => {
        const {status,progress} = action.payload;

        if(state.downladProgress === undefined) state.downladProgress = {progressStatus:'',progress:0}

        if(status)
          state.downladProgress.progressStatus = status
        if(progress)
          state.downladProgress.progress = progress
      },
      startVideoUpload: (state, action: PayloadAction<{index:number}>) => {
        const {index} = action.payload
        state.videoUpload = {index,status:'START_UPLOADING'}
      },

      resetVideoUpload: (state) => {
        state.videoUpload = undefined
      },

      setUploadActive: (state) => {
        state.uploadActive = true
      },

      setUploadInActive: (state) => {
        state.uploadActive = false
      }

}}
);

export const {updateVideoProgressStatus,updateDownloadStatus, setUploadActive,setUploadInActive,
   resetVideoProgress, initiateProgressTemplate,addVideoProgress,startVideoUpload,resetVideoUpload, removeVideoProgress, updateProcessingStatus} = progressSlice.actions

export const progressReducer =  progressSlice.reducer