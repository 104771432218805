

let pages = [
    {
        title : 'Choose template',
        url : '',
        pageHeading : 'Choose a template'
    },
    {
        title : 'Player Info',
        url : 'player',
        pageHeading : 'Enter player Info'
    },
    {
        title : 'Videos',
        url : 'videos',
        pageHeading : 'Upload Videos'
    },
    {
        title : 'Processing & Download',
        url : 'processing',
        pageHeading : ''
    },
    {
        title : 'Payment',
        url : 'payment',
        pageHeading : ''
    },
]

export default pages