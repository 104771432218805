
import React, { useEffect, useRef, useState } from 'react';

import './payment-page.css'
import { PayPalButtons,FUNDING, PayPalHostedField, PayPalHostedFieldsProvider, PayPalScriptProvider, usePayPalHostedFields, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import axios from 'axios';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducer';


const PaymentPage: React.FC = () => {


    // const [{ isPending,isRejected,isResolved }] = usePayPalScriptReducer();
    const [{ isResolved,isPending,isRejected, options }] = usePayPalScriptReducer();
    const [orderId, setOrderId] = useState()

    const [paypanetStatus, setPaymentStatus] = useState('PENDING')

    let userId =  useSelector((state:RootState) => state.user).userId


    let downloadStatus = useSelector((state:RootState) => state.progress).downladProgress

    const createOrder = (data: any, actions: any) => {
        console.log('Called creatd dorder')
        setPaymentStatus('IN_PROGRESS')
        return fetch('https://server.oyak.in/create-order/'+userId)
        .then((response) => {
            console.log(response)
            return response.json()
        })
        .then((order) => {
            console.log(order)
            setOrderId(order.id)
            return order.id
        })
        .catch(e => console.log(e))
           
    };

    useEffect(() => {
        if(downloadStatus?.progressStatus === 'COMPLETED')
            if(paypanetStatus === 'COMPLETED'){
                fetchDownloadUrl()
            }
    },[paypanetStatus,downloadStatus])

    let [downloadUrl, serDownloadUrl] = useState()

    const fetchDownloadUrl = async() => {
        const resp = await axios('https://server.oyak.in/get_download_url/'+userId)

        if(resp.status === 200 && resp.data.status){
            serDownloadUrl(resp.data.url)
        }
    }

    const onApprove = (data: any, actions: any) => {
    
        setPaymentStatus('WAITING_FOR_APPROVAL')
        console.log('Called creatd dorder')
        return fetch('https://server.oyak.in/capture-payment/'+data.orderID)
        .then((response) => {
            return response.json()
        }).then(data => {
            if(data.status){
                if(data.status === true){
                    setPaymentStatus(data.paymentStatus)
                }   
            }
        })
        .catch(e => console.log(e))

    };

  return (
    <div className='payment-page'>
    {

        paypanetStatus === 'PENDING' || paypanetStatus === 'IN_PROGRESS' ?
            <div className='payment-container'>

                <h1 className='page-heading'>Complete Payment</h1>
                    <PayPalButtons
                    
                        createOrder={createOrder}
                        // @ts-ignore
                        onApprove={onApprove}
                            // @ts-ignore
                        // options={options}
                        />
               
   
            </div> : 
            paypanetStatus === 'COMPLETED' ? 
            <div className='payment-success-ui'>
                <h1 className='page-heading'>Payment Successfull</h1>
                {
                    downloadUrl !== null ?
                    <a href={downloadUrl}>Click this link to download the video</a> : 'Please wait while we get the video link.'
                }
                
            </div>
            : paypanetStatus === 'WAITING_FOR_APPROVAL' &&
            'Please wait while we verify the transction' 

         
  
    }  
    
    </div>
  );
};

export default PaymentPage;