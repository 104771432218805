import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';


export interface playerState {
    [key:string] : string|undefined|number|string[];
    selectedPositions:string[]|undefined
    
}

// export const initialState:playerState = {
//   fullname:'',
//   grad_year:'',
//   school:'',
//   gpa:'',
//   selectedPositions:[],phone:'',email:'',twitter:''
// }

export const initialState:playerState = {
  fullname:'',
  grad_year:'',
  school:'',
  gpa:'',
  selectedPositions:[],phone:'',email:'',twitter:''
}


const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    updatePlayerState: (state:playerState, action: PayloadAction<{ key: string; value: string|string[] }>) => {
        const { key, value } = action.payload;
        state[key] = value;
      },

    updateAutoComplete: (state, action: PayloadAction<string[]>) => {
        state.selectedPositions = action.payload;
    },
  }
});

export const {updatePlayerState, updateAutoComplete} = playerSlice.actions

export const playerReducer = playerSlice.reducer