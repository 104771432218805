export const templateInfo = [
    {
        index : 0,
        title : 'Pitcher',
        subtitle : 'Baseball',
        templateId: 'bs_pitch'
    },
    {
        index : 1,
        title : 'Position Player',
        subtitle : 'Baseball',
        templateId: 'bs_position'
    },
    {
        index : 2,
        title : 'Pitcher',
        subtitle : 'Softball',
        templateId: 'sb_pitch'
    },
    {
        index : 3,
        title : 'Position Player',
        subtitle : 'Softball',
        templateId: 'sb_position'
    }
]