import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import pages from '../../../utils/pagesInfo';
import TemplateItem from '../../../components/TemplateItem';

import './template-page.css'

import { RootState } from '../../../store/reducer/index';
import {setTemplate} from '../../../store/slice/TemplateSlice'

import { templateInfo } from '../../../utils/templateInfo';
import { setVideoTemplate } from '../../../store/slice/VideoSlice';
import { baseball_pitcher_video_template, baseball_position_video_template, softball_pitcher_video_template, softball_position_video_template } from '../../../utils/videolist';
import { initiateProgressTemplate } from '../../../store/slice/ProgressSice';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { ImNext } from 'react-icons/im';
import { GrFormNext, GrFormNextLink } from 'react-icons/gr';


const TemplatePage: React.FC = () => {
  
  let navigate = useNavigate()

  const value = useSelector((state:RootState) => state.template.value);
  const dispatch = useDispatch();

  useEffect(() => {


  },[])

  let selectHandle = (index:number) => {
    dispatch(setTemplate(index))
    let template = undefined
    switch(index){
      case 0: template=baseball_pitcher_video_template; break;
      case 1: template=baseball_position_video_template; break;
      case 2:  template=softball_pitcher_video_template; break;
      case 3:  template=softball_position_video_template; break;
      default:  template=baseball_pitcher_video_template; break;
    }

    if(template === undefined) return

    dispatch(setVideoTemplate({template}))
    dispatch(initiateProgressTemplate({templates : template}))

  }

  let handleNextClick = () => {
    if(value === undefined || value === -1){
      alert('Select a template to proceed')
    } else {
      navigate('/tool/player')
    }
  }

  

  return (
    <div className='template-page-container'>

      
      <h1 className='page-heading'>{pages[0].pageHeading}</h1>

      
      <div className='template-grid-parent'>
        {
          templateInfo.map((i,index) => 
            <TemplateItem key={index} title={i.title} subtitle={i.subtitle} index={i.index} isActive={value === i.index} selectHandle={selectHandle}/>
            )
        }
      </div>

      <div className="action">
          <CustomButton content='Next' icon={<GrFormNextLink />} isDisable={value === -1}  iconPlacement='right' clickHandler={handleNextClick}/>      
      </div>
      
    </div>
  );
};

export default TemplatePage;