import React from 'react';
import MyComponent from '../../components/MyComponent';
import { Link } from 'react-router-dom';

import './homepage.css'
import { homePageHero } from '../../utils/constants';
import { GrNext } from 'react-icons/gr';

const HomePage: React.FC = () => {

  let underMaintaince = process.env.REACT_APP_UNDER_MAINTAINANCE === 'true' ? true : false

  return (
    <div className='home-page'>

      <div className='logo-parent'>

        <div className="logo-line"></div>
        <div className="logo-container">
          <div className="hero-logo-wrapper" >
           <img src="./images/logo.png" className="hero-logo-img" alt="" />
          </div>
          <div className="text-wrapper">
            <p>Prospect Video Creation Tool</p>
            <p className='blog-nav'><a href="https://redseamplanet.com" target='_blank'>Blog</a></p>
          </div>
         
        </div>
      </div>
      
      <div className='home-container'>

        <h1 className='hero-text'>
          Create a prospect video college <br />coaches will actually watch
        </h1>

        <div className='info-parent'>
          <p className='hero-subtitle'>HOW IT WORKS:</p>
          <div className='info-row'>
            <p>Get Started Below</p>
            <GrNext/>
            <p>Input Bio Info</p>
            <GrNext/>
            <p>Upload Video Clips</p>
            <GrNext/>
            <p>Download Prospect Video</p>
            <GrNext/>
            <p>Share with Scouts</p>
          </div>
        </div>
       
        <div className="home-row">
          <div className="left">
              <div className='home-actions'>{
                underMaintaince ? 
                <Link to={'/maintenance'}>
                  <button className='hero-btn'>CREATE VIDEO NOW</button>
                </Link>
                : 
                <Link to={'/tool'}>
                  <button className='hero-btn'>CREATE VIDEO NOW</button>
                </Link>
              }
             
              <p className='pricing'>Pricing: $30</p>
            </div>
          </div>
          <div className="right">
            <ul className='sell-list'>
              <li>Takes less than 15 mins</li>
              <li>Video file ready to send to scouts</li>
              <li>Formatted based on feedback from college coaches</li>
              <li>Affordable alternative to video production companies</li>
            </ul>
          </div>
        </div>
       
       

      </div>

    </div>
  );
};

export default HomePage;