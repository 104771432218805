import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import './video-page.css'
import pages from '../../utils/pagesInfo';
import { BsArrowLeft } from 'react-icons/bs';
import VideoNavbar from '../../components/VideoNavbar';
import {  baseball_pitcher_video_template, baseball_position_video_template, softball_pitcher_video_template, softball_position_video_template } from '../../utils/videolist';
import VideoSelectContainer from '../../containers/VideoSelectContainer/VideoSelectConatiner';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducer';
import { useDispatch } from 'react-redux';

import { videoSelectSetPage } from '../../store/slice/VideoSlice'
import ToolHeader from '../../components/ToolHeader/ToolHeader';

const VideoInputPage: React.FC = () => {
  
  let navigate = useNavigate()
 
  let templateState = useSelector((state:RootState) => state.template)

  let playerState = useSelector((state:RootState) => state.player)

  let isFirstTime = useRef(true)
  useEffect(() => {
    if(!isFirstTime.current) return
    isFirstTime.current = false

    if(templateState.value === undefined || templateState.value === -1)
        return navigate('/tool')
    
    if(playerState['fullname'] === undefined)
        return  navigate('/tool/player')
      
  },[])

  return (
    <div className='video-page'>

      <ToolHeader title={pages[2].pageHeading} path='/tool/player'/>

      <VideoNavbar/>

      <VideoSelectContainer/>
      
      
    </div>
  );
};

export default VideoInputPage;