import AWS from 'aws-sdk';

export let getS3Config = () => {
    let BUCKET_NAME:string = process.env.REACT_APP_AWS_BUCKET ? process.env.REACT_APP_AWS_BUCKET : ''
    const s3 = new AWS.S3({
      endpoint: process.env.REACT_APP_AWS_S3_URL,
      s3ForcePathStyle: true,
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET,
      region: process.env.REACT_APP_AWS_REGION,
      useAccelerateEndpoint: true
    });

    return {s3,BUCKET_NAME}
}