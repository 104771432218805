// src/components/MyComponent.tsx
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducer';
import LinearWithValueLabel from '../ProgressBar';

import {ImFolderUpload} from 'react-icons/im'
import { useDispatch } from 'react-redux';
import { resetVideoUpload, startVideoUpload, updateProcessingStatus, updateVideoProgressStatus } from '../../store/slice/ProgressSice';

import './video-action.css'
import { videoSelectNextPage } from '../../store/slice/VideoSlice';
import CustomButton from '../CustomButton/CustomButton';

interface Props {
  index:number
}

const VideoActionItem: React.FC<Props> = ({ index }) => {

    let stateInfo = {
        UPLOADING : 'Uploading video...',
        OPTIMIZING : 'Optimizing video...'
    }

    const videoElements = useSelector((state:RootState) => state.video).videoTemplate
    const progress = useSelector((state:RootState) => state.progress).videoProgress[index]

    let videoUploadState = useSelector((state:RootState) => state.progress).videoUpload
    let uploadActive = useSelector((state:RootState) => state.progress).uploadActive

    let dispatch = useDispatch()

    const proceedToNext = () => {
        if(progress?.progressStatus === 'COMPLETED'){
            if(index === videoElements.length -1){
                dispatch(updateProcessingStatus({status:'PROCESSING_SUBMITTED'}))
                return
            } else {
                dispatch(videoSelectNextPage())
            }
            return
        }
    }
    const triggerUploadVideo = async() => {

        if(uploadActive){
            alert('Upload already in progress. please wait!')
            return
        }

        dispatch(startVideoUpload({index}))

    }

    return (
        <div className='upload-btn-parent'>
            {
                progress && (progress.progressStatus === 'UPLOADING' || progress.progressStatus === 'OPTIMIZING') ?

                    <div style={{display:'flex', flexDirection:'column', gap:'0px', width:'100%'}}>
                        <LinearWithValueLabel progress={progress.progress ? progress.progress : 0}/>
                        <p style={{lineHeight:'0px', fontSize:'.8em'}}>{stateInfo[progress.progressStatus]}</p>
                    </div>
                    :

                    (progress?.progressStatus=== 'UPLOADED' || progress?.progressStatus=== 'COMPLETED') ?
                    <CustomButton content={index === videoElements.length -1 ? 'Start Processing' : 'Proceed'} clickHandler={proceedToNext}/> 
                    : 
                    <CustomButton content={'Upload & Proceed'} icon={<ImFolderUpload/>} iconPlacement='right' clickHandler={triggerUploadVideo}/> 
                  
            }
            
            

            
        </div>
  );
};

export default VideoActionItem;
