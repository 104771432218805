import React, { useEffect } from 'react';

import pages from './utils/pagesInfo';

import { Routes, Route } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import TemplatePage from './pages/EditorPages/TemplatePage/TemplatePage';
import PlayerInfoPage from './pages/EditorPages/PlayerInfoPage/PlayerInfoPage';
import VideoInputPage from './pages/VideoInputPage/VideoInputPage';
import ProcessingPage from './pages/EditorPages/ProcessingPage/ProcessingPage';
import PaymentPage from './pages/PaymentPage/PaymentPage';

import HomePage from './pages/HomePage/HomePage';
import EditorPage from './pages/EditorPage/EditorPage';

import Undermaintain from './pages/Undermaintenance/Undermaintain';


// import dotenv from 'dotenv'

// dotenv.config()

const App: React.FC = () => {

  return (
    <>
      <Suspense fallback={
      <div className="loading-container">
        <div className="spinner"><div className="lds-dual-ring"></div></div>
      </div>}>

  
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/tool" element={<EditorPage />} >
                <Route path={pages[0].url} element={<TemplatePage />}/>
                <Route path={pages[1].url} element={<PlayerInfoPage />}/>
                <Route path={pages[2].url} element={<VideoInputPage />}/>
                <Route path={pages[3].url} element={<ProcessingPage />}/>
                <Route path={pages[4].url} element={<PaymentPage />}/>
              </Route>
              <Route path="/maintenance" element={<Undermaintain />} />
            </Routes>
 
      </Suspense>
    </>
  );
};

export default App;