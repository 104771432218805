import React from 'react'

type Props = {}

export default function Undermaintain({}: Props) {
  return (
    <div style={{width:'100%',height:'100vh',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column', gap:'60px'}}>
        <img src="./images/under-maintenance.svg" style={{maxWidth:'400px',height:'auto'}} alt="" />
        <a href="/" style={{textDecoration:'none',color:'black',fontSize:'.8em'}}>Back to home page</a>
    </div>
  )
}