import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';


export interface outputState {
    previewUrl?:string,
    downLoadUrl?:string
}

export const initialState:outputState = {
    previewUrl:'',
    downLoadUrl:''
}

const outputSlice = createSlice({
  name: 'output',
  initialState,
  reducers: {
    updatePreviewUrl: (state:outputState, action: PayloadAction<{ url: string}>) => {
        const { url } = action.payload;
        state.previewUrl = url
      },

    updateDownloadUrl: (state:outputState, action: PayloadAction<{ url: string}>) => {
    const { url } = action.payload;
    state.downLoadUrl = url
    },
  }
});

export const {updatePreviewUrl, updateDownloadUrl} = outputSlice.actions

export const outputReducer = outputSlice.reducer