import React, { useState } from 'react';

import './template-item.css'
import templateImg1 from './assets/template-1.png'
import templateImg2 from './assets/template-2.png'
import templateImg3 from './assets/template-3.png'
import templateImg4 from './assets/template-4.png'
import { AiOutlineCheck } from 'react-icons/ai';

interface Props {
  title: string;
  subtitle: string;
  index:number,
  isActive?:boolean,
  selectHandle: (index:number) => void
}

const TemplateItem: React.FC<Props> = ({title, subtitle, index, isActive, selectHandle}) => {


    return (
        <div className={isActive ? 'template-item-container active' : 'template-item-container'} onClick={() => selectHandle(index)}>

            <h1 className='template-item-title'>{title}</h1>
            <p className='template-item-subtitle'>{subtitle}</p>
            <img src={
                index === 0 ? templateImg1 : index === 1 ? templateImg2 : index === 2 ? templateImg3 : templateImg4
            } className='item-bg'/>

            {
                isActive &&
                <div className="check-icon-template">
                    <AiOutlineCheck />
                </div>
            }
     
        </div>
    );
};

export default TemplateItem;
