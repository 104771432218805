// src/components/MyComponent.tsx
import React, { useEffect, useState } from 'react';
import './video-navbar.css'

import {GrFormNext} from 'react-icons/gr'
import {RiVideoAddFill} from 'react-icons/ri'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducer';
import { addToTemplate, addVideo, removeFromTemplate, videoSelectNextPage, videoSelectPrevPage, videoSelectSetPage } from '../../store/slice/VideoSlice';
import { addVideoProgress, removeVideoProgress } from '../../store/slice/ProgressSice';

interface Props {
}

const VideoNavbar: React.FC<Props> = ({  }) => {

   
    const dispatch = useDispatch();

    const videoTemplate = useSelector((state:RootState) => state.video).videoTemplate

    const selectedIndex = useSelector((state:RootState) => state.video).videoSelectpage
  
    let uploadActive = useSelector((state:RootState) => state.progress).uploadActive

    let navChange = (index:number) => {
        if(uploadActive){
            alert('Uploading in progress! Please wait.')
            return
        }
        console.log('Nav change called ' + index)
        dispatch(videoSelectSetPage({index}))
    }

    let addVideoHandle = () => {
        if(uploadActive){
            alert('Uploading in progress! Please wait.')
            return
        }
        dispatch(addToTemplate())
        dispatch(addVideoProgress())
    }

    let removeTemplate = (index:number) => {
        if(uploadActive){
            alert('Uploading in progress! Please wait.')
            return
        }
        if(videoTemplate.length === 1){
            alert('At least on video is required')
            return
        }
        dispatch(removeFromTemplate({index}))
        dispatch(removeVideoProgress({index}))
        
        if(selectedIndex === index){
            dispatch(videoSelectSetPage({index : 0}))
        } else if(selectedIndex > index){
            dispatch(videoSelectPrevPage())
        }
           
    }


    let getVideoListUI = videoTemplate?.map((item,index) =>
       { 
       return <React.Fragment key={index}>
            <div className={selectedIndex === index ? 'video-nav-item active': 'video-nav-item'} 
            key={index}
            onClick={() => navChange(index)}
            >
                {
                    item.title
                }

                <div className='video-nav-item-remove' onClick={(e) => {removeTemplate(index);e.stopPropagation();}}>
                    -
                </div>
            </div>

            {
                index < videoTemplate.length -1  &&
                <div className='video-nav-arrow' key={index + 55}>
                    <GrFormNext />
                </div>
            }

        </React.Fragment>
        }
    )

    return (
        <div className='video-nav-parent'>
            {
                getVideoListUI
            }
         
            <div className='video-nav-arrow' key={55}>
                <GrFormNext />
            </div>
            
            <div className='video-nav-item add-more'
                key={666}
                onClick={() => addVideoHandle()}>
                    <RiVideoAddFill />
            </div>

        </div>
    );
};

export default VideoNavbar;
