// src/components/MyComponent.tsx
import React, { useState } from 'react';

import './instruction-dialog.css'
import { AiOutlineClose } from'react-icons/ai'


import Modal from "react-modal";
Modal.setAppElement("#root");



interface Props {
  instruction: string[]|undefined;
  title:string|undefined;
  isOpen:boolean,
  toggleModal:React.Dispatch<React.SetStateAction<boolean>>;
}


const InstructionDialog: React.FC<Props> = ({ title,instruction, isOpen, toggleModal }) => {

  return (
    <Modal
        isOpen={isOpen}
        onRequestClose={() => toggleModal(!isOpen)}
        contentLabel={title}

        className="instruction-model"
        overlayClassName="modal-overlay"
        closeTimeoutMS={500}
      >
        <div className='modal-container'>

            <div className='model-close' onClick={() => toggleModal(!isOpen)}>
                <AiOutlineClose />
            </div>

            <h2>{title}</h2>

            <p>Instructions</p>

            <ul>
               {
                instruction?.map(i => <li>{i}</li>)
               }
            </ul>
        </div>
    </Modal>
  );
};

export default InstructionDialog;
