export let playerPositions = [
    '1B',
    '2B',
    'SS',
    '3B',
    'OF',
    'C',
    'RHP',
    'LHP',
]

export const homePageHero = "Create a prospect video college coaches will actually watch"