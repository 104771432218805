import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';


interface TemplateState {
  value: number;
  currentPage?:number
}

const templateSlice = createSlice({
  name: 'template',
  initialState: { value: -1,currentPage:0 } as TemplateState,
  reducers: {
    setTemplate(state, action: PayloadAction<number>) {
      state.value = action.payload;
    },

    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    }
  }
});

export const {setTemplate,setCurrentPage} = templateSlice.actions

export const templateReducer =  templateSlice.reducer