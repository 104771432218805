import React, { ReactNode, useEffect, useState } from 'react'

import './button.css'
import { useNavigate } from 'react-router-dom';


type Props = {
    content?:string,
    isFullWidth?:boolean,
    icon?:ReactNode,
    style?:'primary'|'outline'|'text-btn'
    iconPlacement?:'left'|'right',
    isLoading?:boolean,
    isDisable?:boolean,
    type?:'submit'|'button',
    path?:string,
    size?:'middle'|'small',
    clickHandler?:() => void
}


export function CustomButton({content,isFullWidth,icon,iconPlacement,isLoading,isDisable,type,path,style,size, clickHandler}: Props) {

    let [classList,setClassList] = useState('custom-btn')
    let navigate = useNavigate()


    useEffect(() => {
        let classList = 'custom-btn'
        if(isFullWidth) classList += ' full'
        if(isLoading) classList += ' loading'

        if(style) classList += ` ${style}`
        if(size) classList += ` ${size}`
        setClassList(classList)

    },[isFullWidth,isLoading])

    let clickHandle = () => {
        if(clickHandler){
            clickHandler()
            return
        }
        if(path){
            navigate(path)
            return
        }
    }

    return (
        <button className={classList} type={type ? type:'button'} disabled={isDisable} onClick={() => clickHandle()}>
            {
                iconPlacement === 'left' && icon
            }
            <p>{content}</p>
            {
                iconPlacement === 'right' ? isLoading ? <div className="spinner"><div className="lds-dual-ring"></div></div> : icon : ''
            }

{
                isLoading && !icon  ? <div className="spinner"><div className="lds-dual-ring"></div></div> : ''
            }
        </button>
    )
}

export default CustomButton