
import { combineReducers } from "redux";
import { templateReducer } from "../slice/TemplateSlice";
import { playerReducer } from "../slice/PlayerSlice";
import { videosReducer } from "../slice/VideoSlice";
import { progressReducer } from "../slice/ProgressSice";
import { outputReducer } from "../slice/OutputSlice";
import { userReducer } from "../slice/UserSlice";

export const rootReducer = combineReducers({
    template: templateReducer,
    player : playerReducer,
    video: videosReducer,
    progress:progressReducer,
    output:outputReducer,
    user:userReducer
});

export type RootState = ReturnType<typeof rootReducer>;